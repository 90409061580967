import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/sass-introduction",
  "date": "2014-09-30",
  "title": "SASS INTRODUCTION",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "CSS is crafted to be simple, but over time your styles become large and your files become bloated with selectors growing out of control. Scaling simplicity is difficult."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When you scale CSS:`}</p>
    <ul>
      <li parentName="ul">{`Slight variations of colors, fonts, numbers & other properties arise`}</li>
      <li parentName="ul">{`Effective curbing of repetition can decline.`}</li>
      <li parentName="ul">{`Stylesheet size may become unmanageable`}</li>
    </ul>
    <p>{`Enter Sass, the `}<strong parentName="p">{`S`}</strong>{`yntactically `}<strong parentName="p">{`A`}</strong>{`wesome `}<strong parentName="p">{`S`}</strong>{`tylesheets.`}</p>
    <h2>{`What is Sass?`}</h2>
    <p>{`Here is what Sass is:`}</p>
    <ul>
      <li parentName="ul">{`Looks like CSS, but adds features to combat shortcomings.`}</li>
      <li parentName="ul">{`Preprocessor, like CoffeeScript & Haml`}</li>
      <li parentName="ul">{`Sassy CSS (.scss) is the default file extension.`}</li>
      <li parentName="ul">{`CSS is valid SCSS`}</li>
    </ul>
    <p>{`style.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$main: #444;
.btn {
    color: $main;
    display: block;
}
.btn-a {
    color: lighten($main, 30%);
    &:hover {
        color: lighten($main, 40%);
    }
}
`}</code></pre>
    <p>{`style.css`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn {
    color: #444444;
    display: block;
}
.btn-a {
    color: #919191;
}
.btn-a:hover {
    color: #aaaaaa;
}
`}</code></pre>
    <h2>{`Comments`}</h2>
    <p>{`Sass adds `}<inlineCode parentName="p">{`//`}</inlineCode>{` for single line comments - not output after compile`}</p>
    <p>{`style.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// These comments will
// not be output to the
// compiled CSS file
/* This comment will */
`}</code></pre>
    <p>{`style.css`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* This comment will */
`}</code></pre>
    <h2>{`Avoid CSS @import`}</h2>
    <p>{`The CSS @import rule should be avoided to as it prevents parallel downloading. `}<strong parentName="p">{`@import`}</strong>{` with .scss or .sass happens
during compile rather than client-side. Which file extension to use is optional.`}</p>
    <p>{`application.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// Imports styles found in 'buttons.scss'
// when the compiler processes application.scss
@import "buttons";
`}</code></pre>
    <h2>{`Partials`}</h2>
    <p>{`Adding an underscore creates a partial. Partials can be imported, but will not compile to .css`}</p>
    <p>{`application.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// Will import _buttons.sass, buttons.sass,
// _buttons.scss, or buttons.scss
@import "buttons";
`}</code></pre>
    <h2>{`Nesting Selectors`}</h2>
    <p>{`Consider the Sass file below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    border: 1px solid #ccc;
    padding: 20px;
}
.content h2 {
    font-size: 3em;
    margin: 20px 0;
}
.content p {
    font-size: 1.5em;
    margin: 15px 0;
}
`}</code></pre>
    <p>{`When compiled the CSS output will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    border: 1px solid #ccc;
    padding: 20px;
}
.content h2 {
    font-size: 3em;
    margin: 20px 0;
}
.content p {
    font-size: 1.5em;
    margin: 15px 0;
}
`}</code></pre>
    <p>{`Pretty much the same. The whole point of using Sass is to structure your styles in a way easier for you to interpret what you are intending to do. Now look at the following Sass file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    border: 1px solid #ccc;
    padding: 20px;
}

h2 {
    font-size: 3em;
    margin: 20px 0;
}
p {
    font-size: 1.5em;
    margin: 15px 0;
}
`}</code></pre>
    <p>{`This will generate the same CSS file as above, but we have nested selectors in our Sass file.`}</p>
    <h2>{`Nesting Properties`}</h2>
    <p>{`Certain properties with matching namespaces are nestable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn {
    text: {
        decoration: underline;
        transform: lowercase;
    }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn {
    text-decoration: underline;
    text-transform: lowercase;
}
`}</code></pre>
    <p>{`While nesting, the & symbol references the parent selector:`}</p>
    <p>{`application.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    border: 1px solid #ccc;
    padding: 20px;
    .callout {
        border-color: red;
    }
    &.callout {
        border-color: green;
    }
}
`}</code></pre>
    <p>{`application.css`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    border: 1px solid #ccc;
    padding: 20px;
}
.content .callout {
    border-color: red;
}
.content.callout {
    border-color: green;
}
`}</code></pre>
    <p>{`Above, `}<strong parentName="p">{`&`}</strong>{` references `}<strong parentName="p">{`.content`}</strong></p>
    <p>{`application.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`a {
    color: #999;
    &:hover {
        color: #777;
    }
    &:active {
        color: #888;
    }
}
`}</code></pre>
    <p>{`application.css`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sidebar {
    float: right;
    width: 300px;
}
.sidebar h2 {
    color: #777;
}
.users .sidebar h2 {
    color: #444;
}
`}</code></pre>
    <p>{`Nesting is easy but dangerous, so do not nest unnecessarily.`}</p>
    <p>{`Here's an example where over-using nesting can become messy.`}</p>
    <p>{`application.scss`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    background: #ccc;
    .cell {
        h2 {
            a {
                &:hover {
                    color: red;
                }
            }
        }
    }
}
`}</code></pre>
    <p>{`application.css`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
    background: #ccc;
}
.content .cell h2 a:hover {
    color: red;
}
`}</code></pre>
    <p>{`Here the outcome has a dangerous level of specificity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      